import dayjs from 'dayjs';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppType } from '../constants';
import { Cookies, SiteCookies } from '../lib/cookies';
import { BaseModel } from './base';
import { CardModel, CardStatus, ICard } from './cards';
import { CollectionModel } from './collection';
import { ISocketEvent, SocketClient } from './socket-client';
import { IUserGroup } from './user-groups';
import { AnalyticsModel } from './analytics';
import { SocketRoomTypeEnum } from '../constants/socket';

type PrivateFields = '_busy' | '_init' | '_users';

type PrivateUserFields = '_dateJoined' | '_user' | '_setUserInfo';

type PrivateUserSessionFields = '_authenticating' |
'_cards' |
'_groupedCards' |
'_cardsLoaded' |
'_cardsProcessing' |
'_checkedIfHasTransactions' |
'_checkingIfHasTransactions' |
'_createPasswordEmail' |
'_createPasswordTokenSuccess' |
'_creatingPasswordToken' |
'_groups' |
'_groupsLoaded' |
'_hasPayPal' |
'_hasKard' |
'_hasKarmaWalletCard' |
'_hasTransactions' |
'_hasUnpaidMembership' |
'_initialized' |
'_joiningGroup' |
'_leavingGroup' |
'_loadingCards' |
'_loadingGroups' |
'_user' |
'_onSocketEvent' |
'_passwordTokenValid' |
'_passwordTokenChecked' |
'_registering' |
'_registeringKard' |
'_removingCard' |
'_resendingGroupEmailVerification' |
'_resettingPassword' |
'_resetPasswordSuccess' |
'_setUserInfo' |
'_shareASaleTrackingId' |
'_transactionsReady' |
'_updating' |
'_updatingPassword' |
'_verifyingEmail' |
'_verifyingPasswordToken' |
'_sendingSupportTicket' |
'_sendingEmailChangeRequest' |
'_sessionIsIdle';

interface IUserHasTransactions {
  hasTransactions: boolean;
}

export interface UserCard {
  _id: string;
  name: string;
  mask: string;
  type: string;
  subtype: string;
  status: CardStatus;
  institution: string;
  createdOn: Date;
  lastModified: Date;
  unlinkedDate?: Date;
  removedDate?: Date;
  initialTransactionsProcessing: boolean;
  lastTransactionSync: Date;
  institutionId?: string;
  isEnrolledInAutomaticRewards?: boolean;
}

export enum UserRoles {
  None = 'none',
  Member = 'member',
  Admin = 'admin',
  SuperAdmin = 'superadmin',
}

export const UserGroups = {
  NotNone: [UserRoles.Member, UserRoles.Admin, UserRoles.SuperAdmin],
  Admins: [UserRoles.Admin, UserRoles.SuperAdmin],
  SuperAdmins: [UserRoles.SuperAdmin],
};

export enum UserEmailStatus {
  Unverified = 'unverified',
  Verified = 'verified',
}

export interface IUserEmail {
  email: string;
  status: UserEmailStatus;
  primary: boolean;
}

export interface IUserBaseData {
  name: string;
  password?: string;
  zipcode: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
}

export interface IUserUpdateEmail {
  email: string;
  pw: string;
}

export interface IUserUpdateZipAndName {
  name: string;
  zipcode: string;
}

export interface IUserUpdateAddress {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  postal_code: string;
}

export interface IUserUpdateMarqeta {
  integrations: {
    marqeta: IUserUpdateAddress
  }
}

export interface IUserUpdateParams {
  referralParams: IUrlParam[];
}

export interface IUrlParam {
  key: string;
  value: string;
}

export interface IUserRegistrationData {
  name: string;
  password: string;
  token: string;
  promo?: string;
  zipcode?: string;
}

export interface ICreatePasswordTokenData {
  email: string;
}

export interface IResetPasswordData {
  token: string;
  newPassword: string;
}

export interface IMessageResponse {
  message: string;
}

export interface IRegisterKardRewardsData {
  lastFour: string;
  bin: string;
}

/**
 * types for the user data returnd from the API
 */

interface IPaypalIntegration {
  payerId: string;
  email: string;
}

interface IKardIntegration {
  userId: string;
  dateAccountCreated: Date;
}

interface IShareASaleIntegration {
  _id: string;
  trackingId: string;
}

interface IPersonaIntegration {
  accountId: string;
}

interface IMarqetaIntegration {
  userToken: string;
  first_name: string;
  last_name: string;
  email: string;
  city: string;
  postal_code: string;
  state: string;
  address1: string;
  address2?: string;
  phone: string;
  country: string;
  status: string;
  created_time: string;
}

interface IUserIntegrations {
  paypal?: IPaypalIntegration;
  kard?: IKardIntegration;
  shareasale?: IShareASaleIntegration;
  marqeta?: IMarqetaIntegration;
  persona?: IPersonaIntegration;
}

interface IKarmaMembership {
  status: 'active' | 'unpaid' | 'canceled';
  productSubscription: string;
  createdOn: Date;
  lastModified: Date;
}

export interface IUser extends IUserBaseData {
  _id: string;
  authKey?: string;
  avatar?: string;
  dateJoined: string;
  emails: IUserEmail[];
  groups?: IUserGroup[];
  role?: UserRoles;
  integrations?: IUserIntegrations;
  accountIsLocked?: boolean;
  karmaMembership: IKarmaMembership;
}

export interface IUserInterface {
  user: IUser;
  groupCode?: string;
}

export interface IPasswordData {
  newPassword: string;
  password: string;
}

export interface ISupportTicketData {
  message: string;
}

export interface IConfirmChangeEmailData {
  verifyToken: string;
  email: string;
  password: string;
}

export interface IAffirmChangeEmailData {
  affirmToken: string;
}

export interface IPasswordTokenResponse {
  created: string;
  expiration: string;
  valid: boolean;
}

export class UserModel extends BaseModel {
  private _dateJoined: dayjs.Dayjs = null;
  private _user: IUser = null;

  constructor(basePath: string, userInfo: IUser) {
    super({ basePath });
    makeObservable<UserModel, PrivateUserFields>(this, {
      _dateJoined: observable,
      _user: observable,
      _id: computed,
      emails: computed,
      primaryEmail: computed,
      name: computed,
      avatar: computed,
      groups: computed,
      role: computed,
      dateJoined: computed,
      zipcode: computed,
      marqetaCreatedOn: computed,
      city: computed,
      state: computed,
      address1: computed,
      address2: computed,
      hasPaypal: computed,
      hasKard: computed,
      payPalEmail: computed,
      shareASaleTrackingId: computed,
      accountIsLocked: computed,
      _setUserInfo: action.bound,
    });
    this._setUserInfo(userInfo);
  }

  get _id() { return this._user?._id; }
  get emails() { return this._user?.emails; }
  get primaryEmail() { return this._user?.emails?.find(e => e.primary); }
  get name() { return this._user?.name; }
  get avatar() { return this._user?.avatar || null; }
  get groups() { return this._user?.groups || []; }
  get role() { return this._user?.role || UserRoles.None; }
  get dateJoined() { return this._dateJoined; }
  get zipcode() { return this._user?.zipcode; }
  get city() { return this._user?.city; }
  get state() { return this._user?.state; }
  get address1() { return this._user?.address1; }
  get address2() { return this._user?.address2; }
  get hasPaypal() { return !!this._user?.integrations?.paypal; }
  get personaAccountId() { return this._user?.integrations?.persona?.accountId; }
  get hasKard() { return !!this._user?.integrations?.kard; }
  get shareASaleTrackingId() { return this._user?.integrations?.shareasale?.trackingId; }
  get marqetaCreatedOn() { return this._user?.integrations?.marqeta?.created_time; }
  get payPalEmail() { return this._user?.integrations?.paypal?.email; }
  get accountIsLocked() { return this._user?.accountIsLocked; }

  public updateUserInfo = (data: Partial<IUser>) => {
    this._user = {
      ...this._user,
      ...data,
    };
  };

  private _setUserInfo = (userInfo: IUser) => {
    this._user = userInfo;
    if (!!userInfo?.dateJoined) this._dateJoined = dayjs(userInfo.dateJoined);
  };
}

/**
 * the model for the current user.
 */
export class UserSessionModel extends BaseModel {
  private _authenticating = false;
  private _cards: CardModel[] = [];
  private _groupedCards: { [key: string]: CardModel[] } = {};
  private _cardsLoaded = false;
  private _cardsProcessing = false;
  private _checkedIfHasTransactions = false;
  private _checkingIfHasTransactions = false;
  private _createPasswordEmail = '';
  private _createPasswordTokenSuccess = false;
  private _creatingPasswordToken = false;
  private _groups: IUserGroup[] = [];
  private _groupsLoaded = false;
  private _hasPayPal = false;
  private _hasKard = false;
  private _hasKarmaWalletCard = false;
  private _hasTransactions = false;
  private _hasUnpaidMembership = false;
  private _initialized = false;
  private _joiningGroup = false;
  private _leavingGroup = false;
  private _loadingCards = false;
  private _loadingGroups = false;
  private _passwordTokenChecked = false;
  private _passwordTokenValid = false;
  private _registering = false;
  private _registeringKard = false;
  private _removingCard = false;
  private _resendingGroupEmailVerification = false;
  private _resettingPassword = false;
  private _resetPasswordSuccess = false;
  private _shareASaleTrackingId = '';
  private _transactionsReady = false;
  private _updating = false;
  private _updatingPassword = false;
  private _user: UserModel = null;
  private _verifyingEmail = false;
  private _verifyingPasswordToken = false;
  private _analyticsModel = new AnalyticsModel();
  private _sendingSupportTicket = false;
  private _sendingEmailChangeRequest = false;
  private _sessionIsIdle = false;

  constructor(socketClient: SocketClient, app: AppType) {
    super({ basePath: '/user' }, socketClient, app);
    makeObservable<UserSessionModel, PrivateUserSessionFields>(this, {
      _authenticating: observable,
      _cards: observable,
      _groupedCards: observable,
      _cardsLoaded: observable,
      _cardsProcessing: observable,
      _checkedIfHasTransactions: observable,
      _checkingIfHasTransactions: observable,
      _createPasswordEmail: observable,
      _creatingPasswordToken: observable,
      _createPasswordTokenSuccess: observable,
      _groups: observable,
      _groupsLoaded: observable,
      _hasPayPal: observable,
      _hasKard: observable,
      _hasKarmaWalletCard: observable,
      _hasTransactions: observable,
      _hasUnpaidMembership: observable,
      _initialized: observable,
      _joiningGroup: observable,
      _leavingGroup: observable,
      _loadingCards: observable,
      _loadingGroups: observable,
      _passwordTokenChecked: observable,
      _passwordTokenValid: observable,
      _registering: observable,
      _registeringKard: observable,
      _removingCard: observable,
      _resendingGroupEmailVerification: observable,
      _resettingPassword: observable,
      _resetPasswordSuccess: observable,
      _shareASaleTrackingId: observable,
      _transactionsReady: observable,
      _updating: observable,
      _updatingPassword: observable,
      _user: observable,
      _verifyingEmail: observable,
      _verifyingPasswordToken: observable,
      _id: computed,
      _sendingSupportTicket: observable,
      _sendingEmailChangeRequest: observable,
      _sessionIsIdle: observable,
      authenticating: computed,
      cards: computed,
      groupedCards: computed,
      cardsLoaded: computed,
      cardsProcessing: computed,
      checkingIfHasTransactions: computed,
      createPasswordTokenSuccess: computed,
      creatingPasswordToken: computed,
      createPasswordEmail: computed,
      emails: computed,
      primaryEmail: computed,
      avatar: computed,
      marqetaCreatedOn: computed,
      dateJoined: computed,
      groups: computed,
      groupsLoaded: computed,
      hasPayPal: computed,
      hasKard: computed,
      hasKarmaWalletCard: computed,
      hasTransactions: computed,
      hasUnpaidMembership: computed,
      initialized: computed,
      isLoggedIn: computed,
      joiningGroup: computed,
      leavingGroup: computed,
      loadingCards: computed,
      loadingGroups: computed,
      name: computed,
      passwordTokenChecked: computed,
      passwordTokenValid: computed,
      registering: computed,
      registeringKard: computed,
      removingCard: computed,
      resendingGroupEmailVerification: computed,
      resettingPassword: computed,
      resetPasswordSuccess: computed,
      role: computed,
      shareASaleTrackingId: computed,
      transactionsReady: computed,
      unlinkedCards: computed,
      verifyingEmail: computed,
      verifyingPasswordToken: computed,
      sessionIsIdle: computed,
      zipcode: computed,
      _onSocketEvent: action.bound,
      _setUserInfo: action.bound,
      checkIfHasTransactions: action.bound,
      joinGroup: action.bound,
      leaveGroup: action.bound,
      loadCards: action.bound,
      loadGroups: action.bound,
      login: action.bound,
      logout: action.bound,
      register: action.bound,
      enrollInKardRewards: action.bound,
      resendGroupEmailVerification: action.bound,
      reset: action.bound,
      resetPassword: action.bound,
      createPasswordToken: action.bound,
      transactionsReadyModalClosed: action.bound,
      updateProfile: action.bound,
      updatePassword: action.bound,
      verifyEmail: action.bound,
      verifyPasswordToken: action.bound,
      verifySession: action.bound,
      sendingSupportTicket: computed,
      sendSupportTicket: action.bound,
      changeEmailRequest: action.bound,
      setHasUnpaidMembership: action.bound,
    });

    this._onSocketEvent.bind(this);
  }

  get _id() { return this._user?._id; }
  get authenticating() { return !!this._authenticating; }
  get avatar() { return this._user?.avatar || null; }
  get cards() { return this._cards || []; }
  get groupedCards() { return this._groupedCards || {}; }
  get cardsLoaded() { return this._cardsLoaded; }
  get cardsProcessing() {
    if (!this.hasCards) return false;
    return this._cards.filter(c => c.processing).length > 0;
  }
  get checkedIfHasTransactions() { return this._checkedIfHasTransactions; }
  get checkingIfHasTransactions() { return this._checkingIfHasTransactions; }
  get createPasswordEmail() { return this._createPasswordEmail; }
  get creatingPasswordToken() { return this._creatingPasswordToken; }
  get createPasswordTokenSuccess() { return this._createPasswordTokenSuccess; }
  get dateJoined() { return this._user.dateJoined; }
  get marqetaCreatedOn() { return this._user.marqetaCreatedOn; }
  get emails() { return this._user?.emails; }
  get groups() { return this._groups || []; }
  get groupsLoaded() { return this._groupsLoaded; }
  get hasPayPal() { return this._user?.hasPaypal; }
  get personaAccountId() { return this._user?.personaAccountId; }
  get hasKarmaWalletCard() { return this._hasKarmaWalletCard || false; }
  get shareASaleTrackingId() { return this._user?.shareASaleTrackingId; }
  get hasCards() { return !!this._cards?.length; }
  get hasTransactions() { return this._hasTransactions; }
  get hasUnpaidMembership() { return this._hasUnpaidMembership; }
  get hasUnlinkedCards() {
    if (!this.hasCards) return false;
    return !!this._cards.filter(card => card.status === CardStatus.Unlinked).length;
  }
  get hasKard() {
    if (!this.hasCards) return false;
    return !!this._cards.reduce((acc, cur) => {
      if (acc) return true;
      if(cur.isEnrolledInAutomaticRewards) return true;
      return false;
    }, false);
  }
  get initialized() { return this._initialized; }
  get isLoggedIn() { return !!this._user?._id; }
  get joiningGroup() { return this._joiningGroup; }
  get leavingGroup() { return this._leavingGroup; }
  get loadingCards() { return this._loadingCards; } 
  get loadingGroups() { return this._loadingGroups; }
  get name() { return this._user?.name; }
  get passwordTokenValid() { return this._passwordTokenValid; }
  get passwordTokenChecked() { return this._passwordTokenChecked; }
  get payPalEmail() { return this._user.payPalEmail; }
  get primaryEmail() { return this._user?.emails?.find(e => e.primary); }
  get registering() { return !!this._registering; }
  get registeringKard() { return !!this._registeringKard; }
  get removingCard() { return this._removingCard; }
  get resendingGroupEmailVerification() { return this._resendingGroupEmailVerification; }
  get resettingPassword() { return this._resettingPassword; }
  get resetPasswordSuccess() { return this._resetPasswordSuccess; }
  get role() { return this._user?.role || UserRoles.None; }
  get transactionsReady() { return this._transactionsReady; }
  get unlinkedCards() { return this._cards.filter(card => card.status === CardStatus.Unlinked); }
  get updating() { return !!this._updating; }
  get updatingPassword() { return !!this._updatingPassword; }
  get verifyingEmail() { return this._verifyingEmail; }
  get verifyingPasswordToken() { return this._verifyingPasswordToken; }
  get zipcode() { return this._user?.zipcode; }
  get address1() { return this._user?.address1; }
  get address2() { return this._user?.address2; }
  get city() { return this._user?.city; }
  get state() { return this._user?.state; }
  get sendingSupportTicket() { return this._sendingSupportTicket; }
  get sendingEmailChangeRequest() { return this._sendingEmailChangeRequest; }
  get sessionIsIdle() { return this._sessionIsIdle; }

  setHasUnpaidMembership (value: boolean) {
    this._hasUnpaidMembership = value;
  }

  public setSessionIsIdle = (value: boolean) => {
    runInAction(() => {
      this._sessionIsIdle = value;
    });
  };

  public checkIfHasTransactions = async () => {
    if (!this._id || this._checkedIfHasTransactions || this._checkingIfHasTransactions) return;
    this._checkingIfHasTransactions = true;

    const result = await this.webServiceHelper.sendRequest<IUserHasTransactions>(
      {
        path: '/transaction/has-transactions',
        method: 'GET',
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._hasTransactions = result.value.hasTransactions;
        this._checkedIfHasTransactions = true;
        this._checkingIfHasTransactions = false;
      });
    } else {
      runInAction(() => {
        this._checkingIfHasTransactions = false;
      });

      throw new Error(result.error);
    }
  };

  public createPasswordToken = async (data: ICreatePasswordTokenData) => {
    if (this._creatingPasswordToken) return;
    this._creatingPasswordToken = true;

    const result = await this.webServiceHelper.sendRequest<IMessageResponse>({
      path: '/password/token/create',
      method: 'POST',
      data,
    });

    runInAction(() => {
      this._creatingPasswordToken = false;
    });

    if (!result.success) throw new Error(result.error);
  };

  public joinGroup = async (code: string, email: string) => {
    if (this._joiningGroup) return;
    this._joiningGroup = true;

    const result = await this.webServiceHelper.sendRequest<IUserGroup>(
      {
        path: '/group/join',
        method: 'POST',
        data: { code, email, userId: this._id },
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._groups.push(result.value);
        this._loadingGroups = false;
        this._groupsLoaded = true;
        this._joiningGroup = false;
      });
    } else {
      runInAction(() => {
        this._loadingGroups = false;
        this._joiningGroup = false;
      });

      throw new Error(result.error);
    }
  };

  public leaveGroup = async (groupId: string) => {
    if (this._leavingGroup) return;
    this._leavingGroup = true;

    const result = await this.webServiceHelper.sendRequest<IUserGroup[]>(
      {
        path: '/group/leave',
        method: 'PUT',
        data: { userId: this._id, groupId },
      },
      true,
    );

    runInAction(() => {
      this._leavingGroup = false;
    });

    if (!result.success) {
      throw new Error(result.error);
    }
  };

  public loadCards = async (refreshData?: boolean) => {
    if (!this.isLoggedIn || this._loadingCards || (!refreshData && this._cardsLoaded)) return;
    this._loadingCards = true;

    const result = await this.webServiceHelper.sendRequest<ICard[]>(
      {
        path: '/card',
        method: 'GET',
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        const hasKarmaCard = result.value.find(c => c.integrations.marqeta);
        if (!!hasKarmaCard) this._hasKarmaWalletCard = true;
        const mappedCards = result.value.map(c => new CardModel(c));
        this._cards = mappedCards;
        this._groupedCards = mappedCards.reduce((acc: { [key: string]: CardModel[] }, item: CardModel) => {
          if (!item) return acc;
          if (!acc[item.institution]) {
            acc[item.institution] = [];
            acc[item.institution].push(item);
          } else {
            acc[item.institution].push(item);
          }

          return acc;
        }, {});
        this._loadingCards = false;
        this._cardsLoaded = true;
      });
    } else {
      runInAction(() => {
        this._loadingCards = false;
      });

      throw new Error(result.error);
    }
  };

  public loadGroups = async (allowReload?: boolean) => {
    if (this.loadingGroups || (this._groupsLoaded && !allowReload)) return;
    this._loadingGroups = true;

    const result = await this.webServiceHelper.sendRequest<IUserGroup[]>(
      {
        path: `/groups/user/${this._id}`,
        method: 'GET',
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._groups = result.value;
        this._loadingGroups = false;
        this._groupsLoaded = true;
      });
    } else {
      runInAction(() => {
        this._loadingGroups = false;
      });

      throw new Error(result.error);
    }
  };

  public login = async (email: string, password: string) => {
    if (this.authenticating) return;
    this._authenticating = true;

    const result = await this.webServiceHelper.sendRequest<IUser>({
      path: '/login',
      method: 'POST',
      data: { email, password },
    });

    if (result.success) {
      // TODO: do I need to test if authentication was successful??? or will error handle this?
      runInAction(() => {
        this._setUserInfo(result.value);
        this._initSocket();
        this._authenticating = false;
        this._initialized = true;
        this._analyticsModel.fireHeapIdentify(
          result.value._id,
          result.value.name,
          result.value.emails.find((e) => !!e.primary).email,
        );

        this._hasUnpaidMembership = result.value.karmaMembership?.status === 'unpaid';
      });
    } else {
      runInAction(() => {
        this.reset();
        this._authenticating = false;
        this._initialized = true;
      });

      throw new Error(result.error);
    }
  };

  public logout = async () => {
    const result = await this.webServiceHelper.sendRequest<IUser>({
      path: '/logout',
      method: 'POST',
    });

    if (result.success) {
      runInAction(this.reset);
      this.socketClient.reconnect();
    } else {
      throw new Error(result.error);
    }
  };

  public register = async (registrationData: IUserRegistrationData) => {
    if (this._registering) return;
    this._registering = true;

    const result = await this.webServiceHelper.sendRequest<IUserInterface>({
      path: '/register',
      method: 'POST',
      data: registrationData,
    });

    if (result.success) {
      // TODO: do I need to test if authentication was successful??? or will error handle this?
      runInAction(() => {
        this._setUserInfo(result.value.user);
        this._initSocket();
        this._authenticating = false;
        this._initialized = true;
        this._registering = false;
        this._analyticsModel.fireHeapIdentify(
          result.value.user._id,
          result.value.user.name,
          result.value.user.emails.find((e) => !!e.primary).email,
        );
      });

      return result.value;
    } else {
      runInAction(() => {
        this.reset();
        this._authenticating = false;
        this._initialized = true;
        this._registering = false;
      });

      throw new Error(result.error);
    }
  };

  public async enrollInKardRewards(data: IRegisterKardRewardsData, id: string) {
    if (this._registeringKard) return;
    runInAction(() => {
      this._registeringKard = true;
    });

    const result = await this.webServiceHelper.sendRequest<UserCard>(
      {
        path: `/card/${id}/rewards`,
        method: 'POST',
        data,
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._registeringKard = false;
      });
    } else {
      runInAction(() => {
        this._registeringKard = false;
      });
      throw new Error(result.error);
    }
  }

  public removeCard = async (groupedCards: CardModel[], removeData: boolean) => {
    if (this._removingCard) return;
    this._removingCard = true;

    const result = await this.webServiceHelper.sendRequest<IUser>(
      {
        path: `/card/${groupedCards[0]._id}/remove`,
        method: 'PUT',
        data: { removeData },
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        const cardIds = groupedCards.map((c) => c._id);
        this._cards = this._cards.filter((c) => !cardIds.includes(c._id));
        this._removingCard = false;
      });
    } else {
      runInAction(() => {
        this._removingCard = false;
      });

      throw new Error(result.error);
    }
  };

  public resendGroupEmailVerification = async (email: string, groupName: string) => {
    if (this._resendingGroupEmailVerification) return;
    this._resendingGroupEmailVerification = true;

    const result = await this.webServiceHelper.sendRequest<IUser>({
      path: '/email/token/create',
      method: 'POST',
      data: { email, groupName },
    });

    runInAction(() => {
      this._resendingGroupEmailVerification = false;
    });

    if (!result.success) {
      throw new Error(result.error);
    }
  };

  public reset = () => {
    try {
      this._authenticating = false;
      this._cards = [];
      this._groupedCards = {};
      this._cardsLoaded = false;
      this._checkedIfHasTransactions = false;
      this._checkingIfHasTransactions = false;
      this._createPasswordTokenSuccess = false;
      this._creatingPasswordToken = false;
      this._groups = [];
      this._groupsLoaded = false;
      this._hasTransactions = false;
      this._hasKarmaWalletCard = false;
      this._hasUnpaidMembership = false;
      this._joiningGroup = false;
      this._leavingGroup = false;
      this._loadingCards = false;
      this._loadingGroups = false;
      this._passwordTokenChecked = false;
      this._passwordTokenValid = false;
      this._registering = false;
      this._removingCard = false;
      this._resendingGroupEmailVerification = false;
      this._resetPasswordSuccess = false;
      this._resettingPassword = false;
      this._updating = false;
      this._updatingPassword = false;
      this._user = null;
      this._verifyingEmail = false;
      this._verifyingEmail = false;
      this._verifyingPasswordToken = false;
      this.socketClient.off('update', this._onSocketEvent);

      Cookies.remove(SiteCookies.AuthKey);
      Cookies.remove(SiteCookies.LegacyAuthKey);
    } catch {
      // swallowing error
    }
  };

  public resetPassword = async (data: IResetPasswordData) => {
    if (this._resettingPassword) return;
    this._resettingPassword = true;

    const result = await this.webServiceHelper.sendRequest<IMessageResponse>({
      path: '/password/token',
      method: 'PUT',
      data,
    });

    if (result.success) {
      runInAction(() => {
        this._resetPasswordSuccess = true;
        this._resettingPassword = false;
      });
    } else {
      runInAction(() => {
        this._resetPasswordSuccess = false;
        this._resettingPassword = false;
      });

      throw new Error(result.error);
    }
  };

  public updateProfile = async (data: IUserUpdateEmail | IUserUpdateZipAndName | IUserUpdateMarqeta | IUserUpdateParams) => {
    if (this._updating) return;
    this._updating = true;
    const result = await this.webServiceHelper.sendRequest<IUser>({
      path: '/profile',
      method: 'PUT',
      data,
    });

    if (result.success) {
      runInAction(() => {
        this._setUserInfo(result.value);
        this._updating = false;
      });
    } else {
      runInAction(() => {
        this._updating = false;
      });

      throw new Error(result.error);
    }
  };

  public transactionsReadyModalClosed = () => {
    this._transactionsReady = false;
  };

  public updatePassword = async (data: IPasswordData) => {
    if (this._updatingPassword) return;
    this._updatingPassword = true;

    const result = await this.webServiceHelper.sendRequest<IPasswordData>({
      path: '/password',
      method: 'PUT',
      data,
    });

    runInAction(() => {
      this._updatingPassword = false;
    });

    if (!result.success) throw new Error(result.error);
  };

  public verifySession = async () => {
    // TODO: remove use of Cookies.get(SiteCookies.LegacyAuthKey) after a couple weeks of running refactor
    if (!this.authenticating && (!!Cookies.get(SiteCookies.AuthKey) || !!Cookies.get(SiteCookies.LegacyAuthKey))) {
      this._authenticating = true;

      const result = await this.webServiceHelper.sendRequest<IUser>({
        path: '/session',
        method: 'GET',
      });

      if (result.success) {
        runInAction(() => {
          this._setUserInfo(result.value);
          this._authenticating = false;
          this._initSocket();
          this._initialized = true;
          this._hasUnpaidMembership = result.value.karmaMembership?.status === 'unpaid';
          this._analyticsModel.fireHeapIdentify(
            result.value._id,
            result.value.name,
            result.value.emails.find((e) => !!e.primary).email,
          );
        });
      } else {
        runInAction(() => {
          this.reset();
          this._authenticating = false;
          this._initSocket();
          this._initialized = true;
        });
      }
    } else {
      this._initSocket();
      this._initialized = true;
    }
  };

  public verifyEmail = async (token: string) => {
    if (this._verifyingEmail) return;
    if (!token) throw new Error('A token is required to verify an email.');

    this._verifyingEmail = true;

    const result = await this.webServiceHelper.sendRequest<{ email: string }>({
      path: '/email/token/verify',
      method: 'POST',
      data: { tokenValue: token },
    });

    if (result.success) {
      runInAction(() => {
        this._verifyingEmail = false;
      });

      return result.value;
    } else {
      runInAction(() => {
        this._verifyingEmail = false;
      });

      throw new Error(result.error);
    }
  };

  public verifyPasswordToken = async (token: string) => {
    if (this._verifyingPasswordToken) return;
    this._verifyingPasswordToken = true;

    const result = await this.webServiceHelper.sendRequest<void>({
      path: '/password/token/verify',
      method: 'POST',
      data: { token },
    });

    if (result.success) {
      runInAction(() => {
        this._passwordTokenValid = true;
        this._verifyingPasswordToken = false;
        this._passwordTokenChecked = true;
      });
    } else {
      runInAction(() => {
        this._passwordTokenValid = false;
        this._verifyingPasswordToken = false;
        this._passwordTokenChecked = true;
      });

      throw new Error(result.error);
    }
  };

  private _initSocket = () => {
    this.socketClient.reconnect();

    if (!!this._user?._id) this.socketClient.emit(`room/join/${SocketRoomTypeEnum.User}`, { room: this._user._id }); // join user's private room

    this.socketClient.on('update', this._onSocketEvent);
  };

  private _onSocketEvent = ({ type }: ISocketEvent) => {
    if (type === 'plaidTransactionsReady') {
      this._transactionsReady = true;
    }
  };

  private _setUserInfo = (userInfo: IUser) => {
    const city = userInfo?.integrations?.marqeta?.city;
    const state = userInfo?.integrations?.marqeta?.state;
    const address1 = userInfo?.integrations?.marqeta?.address1;
    const address2 = userInfo?.integrations?.marqeta?.address2;
    const zipcode = userInfo?.integrations?.marqeta?.postal_code;

    if (city && state && address1) {
      userInfo.city = city;
      userInfo.state = state;
      userInfo.address1 = address1;
    }

    if (address2) {
      userInfo.address2 = address2;
    }

    if (zipcode) {
      userInfo.zipcode = zipcode;
    }

    this._user = new UserModel(this._basePath, userInfo);
  };

  public updateUserSession = (userInfo: IUser) => {
    if (!this._user) {
      this._user = new UserModel(this._basePath, userInfo);
      return;
    }
    this._user.updateUserInfo(userInfo);
  };

  public sendSupportTicket = async (data: ISupportTicketData) => {
    if (this._sendingSupportTicket) return;
    this._sendingSupportTicket = true;

    const result = await this.webServiceHelper.sendRequest<void>({
      path: '/support-ticket',
      method: 'POST',
      data,
    });

    if (result.success) {
      runInAction(() => {
        this._sendingSupportTicket = false;
      });
    } else {
      runInAction(() => {
        this._sendingSupportTicket = false;
      });

      throw new Error(result.error);
    }

    return result;
  };

  public confirmChangeEmail = async (data: IConfirmChangeEmailData) => {
    if (this._sendingEmailChangeRequest) return;
    this._sendingEmailChangeRequest = true;

    const result = await this.webServiceHelper.sendRequest<void>({
      path: '/email/request-change/verify',
      method: 'POST',
      data: data,
    });

    if (result.success) {
      runInAction(() => {
        this._sendingEmailChangeRequest = false;
      });
    } else {
      runInAction(() => {
        this._sendingEmailChangeRequest = false;
      });

      throw new Error(result.error);
    }

    return result;
  };

  public affirmChangeEmail = async (data: IAffirmChangeEmailData) => {
    if (this._sendingEmailChangeRequest) return;
    this._sendingEmailChangeRequest = true;

    const result = await this.webServiceHelper.sendRequest<string>({
      path: '/email/request-change/affirm',
      method: 'POST',
      data: data,
    });

    if (result.success) {
      runInAction(() => {
        this._sendingEmailChangeRequest = false;
        this._user.emails.map((email) => {
          if (email.primary) {
            email.email = result.value;
          }
        });});
    } else {
      runInAction(() => {
        this._sendingEmailChangeRequest = false;
      });

      throw new Error(result.error);
    }

    return result;
  };

  public changeEmailRequest = async (password: string) => {
    if (this._sendingEmailChangeRequest) return;
    this._sendingEmailChangeRequest = true;

    const result = await this.webServiceHelper.sendRequest<void>({
      path: '/email/request-change',
      method: 'POST',
      data: { password },
    });

    if (result.success) {
      runInAction(() => {
        this._sendingEmailChangeRequest = false;
      });
    } else {
      runInAction(() => {
        this._sendingEmailChangeRequest = false;
      });

      throw new Error(result.error);
    }

    return result;
  };
}

export class UsersModel extends BaseModel {
  protected _busy = false;
  protected _users: CollectionModel<IUser, UserModel> = null;

  constructor(basePath = '/users') {
    super({ basePath });
    makeObservable<UsersModel, PrivateFields>(this, {
      _busy: observable,
      _users: observable,
      users: computed,
      busy: computed,
      _init: action.bound,
    });
    this._init();
  }

  get busy() {
    return this._busy;
  }
  get users() {
    return this._users;
  }

  protected _init = () => {
    this._users = new CollectionModel<IUser, UserModel>(
      this._basePath,
      (userInfo: IUser) => new UserModel('/user', userInfo),
    );
  };
}

// adding this for now as this model will eventually
// have additional controls to be used in the admin
// area.
export class AdminUsersModel extends UsersModel {
  private _deletingUser = false;
  private _unlockingUser = false;

  constructor() {
    super('/admin/users');
  }

  get deletingUser() {
    return this._deletingUser;
  }

  public delete = async (userId: string) => {
    if (this._deletingUser) return;
    this._deletingUser = true;

    const result = await this.webServiceHelper.sendRequest<IUser>({
      path: '/',
      method: 'DELETE',
      queryParams: { userId },
    });

    runInAction(() => {
      this._deletingUser = false;
    });
    if (!result.success) {
      throw new Error(result.error);
    }
  };

  public unlockAccount = async (userId: string) => {
    if (this._unlockingUser) return;
    this._unlockingUser = true;

    const result = await this.webServiceHelper.sendRequest<IUser>({
      path: `/${userId}/unlock-account`,
      method: 'PUT',
    });

    runInAction(() => {
      this._unlockingUser = false;
    });
    if (!result.success) {
      throw new Error(result.error);
    }
  };
}
