import React from 'react';
import { CommissionInfoLine, ManageCommissionModalContainer } from './styles';
import { CommissionsModel, KarmaCommissionStatus } from '../../../../models/commisions';
import { ButtonKind } from '../../../../components/Button/styles';
import { useErrorMessages } from '../../../../contexts/error-messages-store';
import { useToaster } from '../../../../contexts/toaster-store';

interface IProps {
  className?: string;
  onClose: () => void;
  isOpen: boolean;
  commissionsModel: CommissionsModel;
}

export const ManageCommissionModal: React.FC<IProps> = ({
  className = '',
  onClose,
  isOpen,
  commissionsModel,
}) => {
  const errorMessages = useErrorMessages();
  const toaster = useToaster();
  const commission = commissionsModel.searchedCommission;
  
  const onCancelUpdateStatusClick = () => {
    onClose();
  };

  const onMarkAsPaidClick = async () => {
    try {
      await commissionsModel.updateCommissionStatus(commissionsModel.searchedCommission._id, KarmaCommissionStatus.PaidToUser, true);
      onClose();
      toaster.push({ message: 'Commission status has been updated to Paid.' });
    } catch (err: any) {
      errorMessages.push({
        title: 'Could not update commission status',
        message: err.message,
      });
    }
  };

  const onMarkAsCanceledClick = async () => {
    try {
      await commissionsModel.updateCommissionStatus(commissionsModel.searchedCommission._id, KarmaCommissionStatus.Canceled, true);
      onClose();
      toaster.push({ message: 'Commission status has been updated to Canceled.' });
    } catch (err: any) {
      errorMessages.push({
        title: 'Could not update commission status',
        message: err.message,
      });
    }
  };

  const CTAs = [
    {
      id: 'mark-as-paid',
      text: 'Mark As Paid',
      kind: ButtonKind.Quaternary,
      onClick: onMarkAsPaidClick,
    },
    {
      id: 'mark-as-canceled',
      text: 'Mark As Canceled',
      kind: ButtonKind.Danger,
      onClick: onMarkAsCanceledClick,
    },
    {
      id: 'cancel-update-status',
      text: 'Cancel',
      kind: ButtonKind.PrimaryGhost,
      onClick: onCancelUpdateStatusClick,
    },
  ];
  
  return (
    <ManageCommissionModalContainer
      className={ className }
      onClose={ onClose }
      isOpen={ isOpen }
      ctas={ CTAs }
      title='Edit Commission Status'
    >
      <CommissionInfoLine><span>Commission ID:</span> { commission?._id }</CommissionInfoLine>
      <CommissionInfoLine><span>Created On:</span> { commission?.createdOn }</CommissionInfoLine>
      <CommissionInfoLine><span>Company:</span> { commission?.company?.companyName }</CommissionInfoLine>
      <CommissionInfoLine><span>Source:</span> { commission?.source }</CommissionInfoLine>
      <CommissionInfoLine><span>User Allocation:</span> ${ commission?.userAmount.toFixed(2) }</CommissionInfoLine>
      <CommissionInfoLine><span>Status:</span> { commission?.status }</CommissionInfoLine>
    </ManageCommissionModalContainer>
  );
};
