import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';
import { ICompanyRef, IMerchant } from './companies';
import { IUser } from './users';

dayjs.extend(utc);

type PrivatePayoutFields = '_busy' | '_commissions' | '_date' | '_errorLoading' | '_total';

type PrivateDashboardFields =
 '_busy'
 | '_lifetimeCashback'
 | '_payouts'
 | '_accruals'
 | '_balance'
 | '_nextPayoutDate'
 | '_errorLoading';

type PrivateCommissionsFields =
  '_busy'
  | '_commissions'
  | '_searchedCommission'
  | '_commissionPayoutsOverviews'
  | '_errorLoadingCommissionPayoutsOverview'
  | '_errorLoading'
  | '_total';

type PrivateCommissionFields = '_busy' | '_commission';

export enum CommissionType {
  Wildfire = 'wildfire',
  Kard = 'kard',
  Karma = 'karma',
  All = 'all',
}

export enum KarmaCommissionStatus {
  Pending = 'pending',
  ConfirmedAndAwaitingVendorPayment = 'confirmed-and-awaiting-vendor-payment',
  ReceivedFromVendor = 'received-from-vendor',
  PaidToUser = 'paidToUser',
  Canceled = 'canceled',
}

export enum WildfireCommissionStatus {
  Pending = 'PENDING',
  Paid = 'PAID',
  Disqualified = 'DISQUALIFIED',
  Ready = 'READY',
}

export enum KarmaCommissionPayoutOverviewStatus {
  AwaitingVerification = 'awaiting-verification',
  Denied = 'denied',
  Processing = 'processing',
  Sent = 'sent',
  Success = 'success',
  Verified = 'verified',
}

export interface IWildfireCommissionIntegration {
  CommissionID: number;
  ApplicationID: number;
  MerchantID: number;
  DeviceID: number;
  SaleAmount: {
    Amount: string;
    Currency: string;
  };
  Amount: {
    Amount: string;
    Currency: string;
  };
  Commission: number;
  Status: WildfireCommissionStatus;
  EventDate: Date;
  CreatedDate: Date;
  ModifiedDate: Date;
  MerchantOrderID: string;
  MerchantSKU: string;
}

export interface ICommissionIntegrations {
  wildfire?: IWildfireCommissionIntegration;
}

export interface ICommission {
  _id: string;
  merchant: IMerchant;
  company: ICompanyRef;
  user: IUser;
  source: CommissionType;
  amount?: number;
  date: Date;
  lastStatusUpdate: Date;
  allocation: {
    karma: number;
    user: number;
  };
  status: KarmaCommissionStatus;
  lastModified: Date;
  createdOn: Date;
  integrations: ICommissionIntegrations;
}

export interface ICommissionsBreakdown {
  karma: number;
  wildfire: number;
  kard: number;
}

export type CommissionDisbursementBreakdown = {
  marqeta: number;
  donate: number;
}

export interface ICommissionPayoutOverview {
  _id: string;
  createdOn: Date;
  payoutDate: Date;
  amount: number;
  status: KarmaCommissionPayoutOverviewStatus;
  commissionPayouts: string[];
  breakdown: ICommissionsBreakdown;
  disbursementBreakdown: CommissionDisbursementBreakdown;
}

export interface IPayoutResponse {
  commissions: ICommission[];
  total: number;
  date: Date;
}

export interface IPayoutSummary {
  _id: string;
  user: IUser;
  date: Date;
  amount: number;
  status: KarmaCommissionStatus;
  commissions: string;
}

export interface IDashboardSummaryResponse {
  lifetimeCashback: number;
  payouts: IPayoutSummary[];
  accruals: ICommission[];
  balance: number;
  nextPayoutDate: Date;
}

// pass thru payout id to get a particular one, otherwise it will return any commisions that are not on a payout
export class PayoutModel extends BaseModel {
  private _busy = false;
  private _commissions: ICommission[] = [];
  private _date: Date | null = null;
  private _errorLoading = false;
  private _total = 0;

  constructor() {
    super();
    makeObservable<PayoutModel, PrivatePayoutFields>(this, {
      _busy: observable,
      _commissions: observable,
      _errorLoading: observable,
      _date: observable,
      _total: observable,
      busy: computed,
      commissions: computed,
      date: computed,
      errorLoading: computed,
      total: computed,
      loadPayout: action.bound,
    });
  }

  get busy() {
    return this._busy;
  }
  get commissions() {
    return this._commissions;
  }
  get date() {
    return this._date;
  }
  get errorLoading() {
    return this._errorLoading;
  }
  get total() {
    return this._total;
  }

  public loadPayout = async (payoutId?: string) => {
    if (this._busy) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IPayoutResponse>({
      path: `/commission/payout${payoutId ? `?id=${payoutId}` : ''}`,
      method: 'GET',
    });

    if (!!result.success) {
      runInAction(() => {
        if (result.value?.commissions) this._commissions = result.value.commissions;
        if (result.value?.total) this._total = result?.value?.total;
        if (result.value?.date) this._date = result?.value?.date;
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._errorLoading = true;
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };
}

export class CommissionsDashboardModel extends BaseModel {
  private _accruals: ICommission[] = [];
  private _balance = 0;
  private _busy = false;
  private _errorLoading = false;
  private _lifetimeCashback = 0;
  private _nextPayoutDate: Date | null = null;
  private _payouts: IPayoutSummary[] = [];

  constructor() {
    super();
    makeObservable<CommissionsDashboardModel, PrivateDashboardFields>(this, {
      _accruals: observable,
      _balance: observable,
      _busy: observable,
      _errorLoading: observable,
      _lifetimeCashback: observable,
      _nextPayoutDate: observable,
      _payouts: observable,
      accruals: computed,
      balance: computed,
      busy: computed,
      errorLoading: computed,
      lifeTimeCashback: computed,
      nextPayoutDate: computed,
      payouts: computed,
      loadDashboardSummary: action.bound,
    });
  }

  get accruals() {
    return this._accruals;
  }
  get busy() {
    return this._busy;
  }
  get balance() {
    return this._balance;
  }
  get errorLoading() {
    return this._errorLoading;
  }
  get lifeTimeCashback() {
    return this._lifetimeCashback;
  }
  get nextPayoutDate() {
    return this._nextPayoutDate;
  }
  get payouts() {
    return this._payouts;
  }

  public loadDashboardSummary = async () => {
    if (this._busy) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IDashboardSummaryResponse>({
      path: '/commission/dashboard',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        if (result?.value?.accruals) this._accruals = result.value.accruals;
        if (result?.value?.balance) this._balance = result.value.balance;
        if (result?.value?.lifetimeCashback) this._lifetimeCashback = result.value.lifetimeCashback;
        if (result?.value?.nextPayoutDate) this._nextPayoutDate = result.value.nextPayoutDate;
        if (result?.value?.payouts) this._payouts = result.value.payouts;
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._errorLoading = true;
        this._busy = false;
      });
    }
  };
}

export class CommissionModel extends BaseModel {
  private _busy = false;
  private _commission: ICommission | null = null;

  constructor(commissionInfo: ICommission) {
    super();
    makeObservable<CommissionModel, PrivateCommissionFields>(this, {
      _busy: observable,
      _commission: observable,
      _id: computed,
      busy: computed,
      createdOn: computed,
      amount: computed,
      status: computed,
      userAmount: computed,
      karmaAmount: computed,
      company: computed,
      source: computed,
    });

    this._commission = commissionInfo;
  }

  get busy() {
    return this._busy;
  }
  get createdOn() {
    return dayjs(this._commission?.createdOn).format('MM/DD/YYYY');
  }
  get _id() {
    return this._commission?._id;
  }
  get amount() {
    return this._commission?.amount;
  }
  get status() {
    return this._commission?.status;
  }
  get userAmount() {
    return this._commission?.allocation.user;
  }
  get karmaAmount() {
    return this._commission?.allocation.karma;
  }
  get company() {
    return this._commission?.company;
  }
  get source() {
    return this._commission?.source;
  }
}

export class CommissionsModel extends BaseModel {
  private _busy = false;
  private _commissions: CommissionModel[] = [];
  private _searchedCommission: CommissionModel | null = null;
  private _commissionPayoutsOverviews: ICommissionPayoutOverview[] = [];
  private _errorLoading = false;
  private _errorLoadingCommissionPayoutsOverview = false;
  private _total = 0;

  constructor() {
    super();
    makeObservable<CommissionsModel, PrivateCommissionsFields>(this, {
      _busy: observable,
      _commissions: observable,
      _searchedCommission: observable,
      _commissionPayoutsOverviews: observable,
      _errorLoading: observable,
      _errorLoadingCommissionPayoutsOverview: observable,
      _total: observable,
      busy: computed,
      commissions: computed,
      searchedCommission: computed,
      commissionPayoutsOverviews: computed,
      errorLoading: computed,
      errorLoadingCommissionPayoutsOverview: computed,
      total: computed,
      loadCommissions: action.bound,
      loadCommissionPayoutsOverviews: action.bound,
      updateCommissionPayoutOverviewStatus: action.bound,
    });
  }

  get busy() {
    return this._busy;
  }
  get commissions() {
    return this._commissions;
  }
  get commissionPayoutsOverviews() {
    return this._commissionPayoutsOverviews;
  }
  get errorLoading() {
    return this._errorLoading;
  }
  get errorLoadingCommissionPayoutsOverview() {
    return this._errorLoadingCommissionPayoutsOverview;
  }
  get total() {
    return this._total;
  }

  get searchedCommission() {
    return this._searchedCommission;
  }

  public clearSearchedCommission = () => {
    runInAction(() => {
      this._busy = true;
      this._searchedCommission = null;
      this._busy = false;
    });
  };

  public updateCommissionStatus = async (commissionId: string, status: KarmaCommissionStatus, isAdmin = false) => {
    if (this._busy || !isAdmin) return;

    runInAction(() => (this._busy = true));

    const result = await this.webServiceHelper.sendRequest<ICommission>({
      path: `/admin/commissions/commission/${commissionId}`,
      method: 'PUT',
      data: {
        status,
      },
    });
    
    if (result.success) {
      runInAction(() => {
        if (!!result.value) {
          this._searchedCommission = new CommissionModel(result.value);
        }
        this._busy = false;
        this.loadCommissions([CommissionType.Kard, CommissionType.Wildfire], true);
      });
    } else {
      runInAction(() => {
        this._errorLoading = true;
        this._busy = false;
      });
      throw new Error(result.error);
    }
  };

  public findCommissionById = async (commissionId: string, isAdmin = false) => {
    if (this._busy || !isAdmin) return;

    runInAction( () => { this._busy = true;});

    const result = await this.webServiceHelper.sendRequest<ICommission>({
      path: `/admin/commissions/commission/${commissionId}`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        if (!!result.value) {
          this._searchedCommission = new CommissionModel(result.value);
        }
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._errorLoading = true;
        this._busy = false;
      });
      throw new Error(result.error);
    }
  };

  public loadCommissions = async (types: CommissionType[], isAdmin = false) => {
    if (this._busy || !isAdmin) return;
    runInAction(() => (this._busy = true));
    const formattedTypes = types?.join('+') || CommissionType.All;

    const result = await this.webServiceHelper.sendRequest<ICommission[]>({
      path: `/admin/commissions/commissions/${formattedTypes}`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        if (!!result.value) {
          this._commissions = result.value.map((commission) => new CommissionModel(commission));
          this._total = result.value.length;
        }
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._errorLoading = true;
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  public loadCommissionPayoutsOverviews = async (isAdmin = false) => {
    if (this._busy || !isAdmin) return;
    runInAction(() => (this._busy = true));

    const result = await this.webServiceHelper.sendRequest<ICommissionPayoutOverview[]>({
      path: '/admin/commissions/commission-payouts-overviews',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        if (!!result.value) this._commissionPayoutsOverviews = result.value;
        this._errorLoadingCommissionPayoutsOverview = false;
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._errorLoadingCommissionPayoutsOverview = true;
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  public payCommissionPayoutOverview = async (commissionPayoutOverviewId: string) => {
    if (this._busy) return;
    runInAction(() => (this._busy = true));

    const result = await this.webServiceHelper.sendRequest<string>({
      path: `/admin/commissions/pay-commission-payout-overview/${commissionPayoutOverviewId}`,
      method: 'PUT',
    });

    if (result.success) {
      runInAction(() => {
        const commissionPayoutOverview = this._commissionPayoutsOverviews.find(
          (cpo) => cpo._id === commissionPayoutOverviewId,
        );
        if (commissionPayoutOverview) {
          commissionPayoutOverview.status = KarmaCommissionPayoutOverviewStatus.Sent;
        }
        this._busy = false;
      });

      return result.value;
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  public updateCommissionPayoutOverviewStatus = async (
    commissionPayoutOverviewId: string,
    status: KarmaCommissionPayoutOverviewStatus,
    isAdmin = false,
  ) => {
    if (this._busy || !isAdmin) return;

    runInAction(() => (this._busy = true));

    const result = await this.webServiceHelper.sendRequest<ICommissionPayoutOverview>({
      path: `/admin/commissions/commission-payouts-overviews/${commissionPayoutOverviewId}`,
      method: 'PUT',
      data: {
        status,
      },
    });

    if (result.success) {
      runInAction(() => {
        const commissionPayoutOverview = this._commissionPayoutsOverviews.find(
          (cpo) => cpo._id === commissionPayoutOverviewId,
        );
        if (commissionPayoutOverview) {
          commissionPayoutOverview.status = status;
        }
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };
}
